.main-page .header {
  display:flex;
  flex-direction:column;
  justify-content:center;
  background-color:white;
  border-bottom: 1px solid #ddd;
  height:100px;
  padding:7px;
   position: sticky;
  top: 0;
  z-index: 1;
  /* width:100%; */
}

.main-page .header .logo-container {
  
  height: 90px;
  width: 150px;
  display: flex;
  align-items: center;
  margin-left: 12px;
 
 /* Adjust width for your logo */
}

.main-page .header .logo-container img {
  height: 48px;
  width: 141px;

  /* Style your logo image here (e.g., width, height) */
}
.main-page .header .switch-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70px;
  height: 30px;
  border-radius: 8px; 
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}


.hover{
  color:black
}


.main-page .header .switch-box span{
  font-weight:bold;
}
.main-page .header .input-box{
  display:flex;
  /* //margin-top: 12px; */
  align-items:center;
  justify-content:space-between;
}

.main-page .bottom-anchor{
  background: #fff;
    position: fixed;
    /* bottom: t?'0px':'-280px', // Corrected this line */
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    z-index: 999;
    border:1px solid gray;
    transition: bottom 0.3s ease-in-out;
    border-top-left-radius:23px;
    border-top-right-radius:23px;
}
.main-page .push-to-bottom{
  bottom:-280px;
}
.main-page .push-to-top{
  bottom:0px;
}
.main-page .bottom-anchor .anchor-box{
  display: flex;
  justify-content: center;
  text-align:center;
  padding:30px;
  overflow-Y:scroll;
  height:150px;
  border-top-left-radius:23px;
  border-top-right-radius:23px;
  margin:12px;
  position: relative;
}
.main-page .bottom-anchor .phone-verify-box{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding:30px;
  height:150px;
  border-top-left-radius:23px;
  border-top-right-radius:23px;
  margin:12px;
 
}

.main-page .bottom-anchor .phone-verify-box .custom-otp-input {
  font-size: 19px;
  font-weight: 400;
  width: 38px;
  height: 40px;
  padding:0;
}

.main-page .bottom-anchor .phone-verify-box  .custom-otp-input:focus {
  border-color: #4096ff;
  
}

.main-page .bottom-anchor .phone-verify-box .ant-input-outlined:focus{
  border-color: #4096ff;
 
}


.main-page .bottom-anchor .cross{
  float:right;
  margin-right:56px;
  margin-bottom:65px;
  font-size:25px;
}

.main-page .cart-box{
  position:fixed;
  bottom:0;
  width:100%;
  background:black;
  z-index:9999;
  padding:9px 0;
}
.search-bar {

  flex-grow: 1; /* Removed for Row/Col layout */
}

.button-container {
   /* Add spacing between buttons */
}
.ant-input:focus {
  border-color:#4096ff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
  box-shadow: 0 0 0 2px rgba(87,168,233, .2);
}
.ant-input:not(:focus) {
  border-color:#d9d9d9;
  outline: none;
  box-shadow: none;
}
.main-page .ant-anchor-wrapper .ant-anchor .ant-anchor-link-title{
  margin: 5px;
  background: #f7f7b6;
  padding: 5px;

}
.main-page .ant-anchor-wrapper .ant-anchor .ant-anchor-link-title{
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
position: relative;
left: -21px;
width:161px;
 display: block; 
margin-block-end: 3px;
color: rgba(0, 0, 0, 0.88);
transition: all 0.3s;
}

@media only screen and (max-width: 500px) {
  .main-page .header .logo-container {
   
    display: flex;
    align-items: center;
    height: 36px;
    width: 150px;
    
   /* Adjust width for your logo */
  }
  
  .main-page .header .input-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    /* margin-top: 13px; */
    position: absolute;
    width: 96%;
    bottom: -7px;
}

  /* .main-page .header .input-box{
    display:flex;
    margin-top: -20px;
    align-items:center;
    justify-content:space-between;
  } */
  .main-page .header .logo-container img {
    height: 36px;
    width: 90px;
    /* Style your logo image here (e.g., width, height) */
  }
}